.chat-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; //
  color: #5d5d5d;
  font-size: 4.267vw;
  font-weight: 500;
  line-height: 6.667vw;

  .icon {
    display: block;
    margin: 0 2.133vw 0 0;
    padding: 3.2vw;
    background: url('../../../images/icons/icon-facebook-color.svg') no-repeat center / 6.133vw;
  }
}

@media screen and (min-width: 576px) {
  .chat-link {
    font-size: 24.5779px;
    line-height: 38.4019px;

    .icon {
      margin: 0 12.2861px 0 0;
      padding: 18.432px;
      background: url('../../../images/icons/icon-facebook-color.svg') no-repeat center / 35.3261px;
    }
  }
}
