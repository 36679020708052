:root {
  --brand-black-color: #0d0605;
  --brand-dark-gray-color: #5d5d5d;
  --brand-light-gray-color: #f6f6f6;
  --brand-white-color: #fefefe;
  --brand-blue-color: #00b2ed;
  --brand-violet-color: #9e51f7;
  --brand-yellow-color: #ffd327;

  --brand-email-color: linear-gradient(80deg, #9e51f7, #05afed, #00b2ed);
  --brand-facebook-color: #1877f2;
  --brand-apple-color: #0d0605;
  --brand-google-color: #4285f4;
  --brand-paypal-color: #0070b8;
}

.dark-mode {
  --brand-black-color: #fefefe;
  --brand-dark-gray-color: #b7b7b7;
  --brand-light-gray-color: #f6f6f6;
  --brand-white-color: #0d0605;
  --brand-blue-color: #00b2ed;
  --brand-violet-color: #9e51f7;
  --brand-yellow-color: #ffd327;
}
