.pay-popup {
  top: initial;
  bottom: 0;
  height: auto;
  border-radius: 3.467vw 3.467vw 0 0;
  background-color: var(--brand-white-color);
  padding: 0 0 9.333vw 0;

  overflow-y: scroll;

  .indicator-btn {
    display: block;
    width: 10.4vw;
    height: 1.333vw;
    margin: 0 auto;
    padding: 1.6vw 0 3.2vw;

    .indicator {
      display: block;
      width: 100%;
      height: 1.333vw;
      border-radius: 0.8vw;
      background-color: var(--brand-dark-gray-color);
    }
  }

  // .qr-box {
  //   width: 34.667vw;
  //   height: 34.667vw;
  //   margin: 10.667vw auto 9.067vw;
  // }

  .total-price {
    text-align: center;
    padding: 9.333vw 0 5.333vw 0;

    .total-price-title {
      display: block;
      color: var(--brand-dark-gray-color);
      font-size: 3.733vw;
      line-height: 5.333vwƒ;
    }

    .currency {
      color: var(--brand-dark-gray-color);
      font-size: 9.6vw;
      line-height: 12.267vw;
    }

    .price {
      font-size: 9.6vw;
      font-weight: 600;
      line-height: 12.267vw;
    }
  }

  &.scroll {
    height: 100%;
  }
}

@media screen and (min-width: 576px) {
  .pay-popup {
    right: 0;
    width: 576px;
    margin: 0 auto;
    border-radius: 19.9699px 19.9699px 0 0;
    padding: 0 0 53.7581px 0;

    .indicator-btn {
      width: 59.8906px;
      height: 7.67188px;
      padding: 9.216px 0 18.432px 0;

      .indicator {
        height: 7.67188px;
        border-radius: 4.608px;
      }
    }

    // .qr-box {
    //   width: 34.667vw;
    //   height: 34.667vw;
    //   margin: 10.667vw auto 9.067vw;
    // }

    .total-price {
      padding: 53.7581px 0 30.7181px 0;

      .total-price-title {
        font-size: 21.5021px;
        line-height: 30.7181px;
      }

      .currency {
        font-size: 55.296px;
        line-height: 70.6579px;
      }

      .price {
        font-size: 55.296px;
        line-height: 70.6579px;
      }
    }
  }
}
