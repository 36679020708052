@import '~function.scss';

.substrate {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  opacity: 0.7;

  &.blue {
    background-color: var(--brand-blue-color);
  }

  &.violet {
    background-color: var(--brand-violet-color);
  }

  &.black {
    background-color: var(--brand-black-color);
  }
}

.small-popup-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
  border-radius: vw(13) vw(13) 0 0;
  padding: vw(6) vw(20) vw(30) vw(20);
  background-color: var(--brand-white-color);

  .indicator-btn {
    display: block;
    margin: 0 auto vw(6);

    .indicator-icon {
      display: block;
      width: vw(39);
      height: vw(5);
      border-radius: vw(3);
      background-color: var(--brand-dark-gray-color);
    }
  }

  .small-popup-title-v1 {
    position: relative;
    height: vw(32);
    margin: 0 0 vw(15) 0;
    text-align: center;
    font-size: vw(16);
    font-weight: 400;
    line-height: vw(32);

    .icon-btn {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
    }

    .add-btn {
      position: absolute;
      top: vw(4);
      right: 0;
      color: var(--brand-blue-color);
      font-size: vw(16);
      line-height: vw(23);
    }
  }

  .small-popup-title-v2 {
    position: relative;
    height: vw(32);
    margin: 0 0 vw(15) 0;
    padding: 0 0 0 vw(44);
    font-size: vw(16);
    font-weight: 700;
    line-height: vw(32);

    .icon-btn {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;

      &.cross {
        left: initial;
        right: 0;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .substrate {
    width: 576px;
    margin: 0 auto;
  }

  .small-popup-wrapper {
    width: 576px;
    margin: 0 auto;
    border-radius: px(13) px(13) 0 0;
    padding: px(6) px(20) px(30) px(20);

    .indicator-btn {
      margin: 0 auto px(6);

      .indicator-icon {
        width: px(39);
        height: px(5);
        border-radius: px(3);
      }
    }

    .small-popup-title-v1 {
      height: px(32);
      margin: 0 0 px(15) 0;
      font-size: px(16);
      line-height: px(32);

      .add-btn {
        top: px(4);
        font-size: px(16);
        line-height: px(23);
      }
    }

    .small-popup-title-v2 {
      height: px(32);
      margin: 0 0 px(15) 0;
      padding: 0 0 0 px(44);
      font-size: px(16);
      line-height: px(32);
    }
  }
}
