@import '~function.scss';

.icon-btn {
  position: relative;
  padding: vw(6);

  .icon-bg {
    display: block;
    width: vw(32);
    height: vw(32);
    padding: vw(7);
    border-radius: vw(13);

    &.black {
      background-color: var(--brand-black-color);
    }

    &.white {
      padding: vw(4);
      border: vw(3) solid var(--brand-light-gray-color);
      background-color: var(--brand-white-color);
    }

    &.violet {
      background-color: var(--brand-violet-color);
    }

    &.gray {
      background-color: var(--brand-light-gray-color);
    }

    &.transparent {
      background-color: transparent;
    }

    &.blue {
      background-color: var(--brand-blue-color);
    }

    svg {
      &.white {
        path,
        circle,
        rect {
          fill: var(--brand-white-color);
        }
      }

      &.black {
        path,
        circle,
        rect {
          fill: var(--brand-black-color);
        }
      }

      &.violet {
        path,
        circle,
        rect {
          fill: var(--brand-violet-color);
        }
      }

      &.gray {
        path,
        circle,
        rect {
          fill: var(--brand-light-gray-color);
        }
      }
    }
  }

  &.pause,
  &.resume {
    .icon-bg {
      padding: vw(9);
    }
  }

  &.pen,
  &.tick {
    .icon-bg {
      padding: vw(8);
    }
  }

  &.cross {
    .icon-bg {
      padding: vw(2);
    }
  }
}

@media screen and (min-width: 576px) {
  .icon-btn {
    padding: px(6);

    .icon-bg {
      width: px(32);
      height: px(32);
      padding: px(7);
      border-radius: px(13);

      &.white {
        padding: px(4);
        border: px(3) solid var(--brand-light-gray-color);
      }
    }

    &.pause,
    &.resume {
      .icon-bg {
        padding: px(9);
      }
    }

    &.pen,
    &.tick {
      .icon-bg {
        padding: px(8);
      }
    }

    &.cross {
      .icon-bg {
        padding: px(2);
      }
    }
  }
}
