@import '../node_modules/normalize.css/normalize.css';
@import './variables.scss';
@import '~function.scss';

* {
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    -webkit-appearance: none;
    display: none;
  }
}

body {
  width: 100%;
  color: var(--brand-black-color);
  background-color: var(--brand-white-color);
  font-family: 'Poppins', sans-serif;
  font-size: vw(12);
  line-height: vw(18);

  @media screen and (min-width: 576px) {
    background-color: var(--brand-light-gray-color);
    font-size: px(12);
    line-height: px(18);
  }
}

#payllery {
  position: relative;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  @media screen and (min-width: 576px) {
    width: 576px;
    margin: 0 auto;

    &:before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    &:before,
    &::after {
      content: '';
      position: fixed;
      top: 0;
      z-index: 20;
      display: block;
      width: calc(50vw - 288px);
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100);
      background-color: var(--brand-light-gray-color);
    }
  }
}

button {
  display: block;
  border: 0;
  padding: 0;
  background-color: transparent;
  color: var(--brand-black-color);
  cursor: pointer;
}

svg {
  display: block;
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

a {
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input,
textarea {
  width: 100%;
  border-radius: vw(13);
  border: 0;
  padding: 0;
  background-color: var(--brand-light-gray-color);
  font-size: vw(12);
  font-weight: 400;
  line-height: vw(16);

  &::placeholder {
    color: var(--brand-dark-gray-color);
  }

  &:focus {
    outline-color: var(--brand-violet-color);
  }

  @media screen and (min-width: 576px) {
    border-radius: px(13);
    font-size: px(12);
    line-height: px(17);
  }
}

textarea {
  resize: none;
}

.no-scroll {
  position: fixed;
  overflow: hidden;
}

figure {
  margin: 0;
}
